import request from '../../utils/request'
// 修改 提交
export function getSubmit(data = {}) {
  return request({
    url: data.id ? '/website/datadictionarydirectory/update' : '/website/datadictionarydirectory/save',
    method: data.id ? 'put' : 'post',
    data
  })
}
// 删除
export function handlDel(data = []) {
  return request({
    url: '/website/datadictionarydirectory/delete',
    method: 'DELETE',
    data
  })
}
// 数据管理
export function getDatamanagement(data = {}) {
  return request({
    url: '/website/datadictionarydirectory/list',
    method: 'post',
    data
  })
}
// 获取type
export function getPare(data) {
  return request({
    url: `/website/common/selectClassEnums`,
    method: 'get',
    data
  })
}

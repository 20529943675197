<template>
  <div class="home">
    <el-drawer
      :title="`${formType === 1 ? '编辑' : '新增'}数据`"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      :before-close="handleBeforeClose"
      size="33%"
    >
      <div>
        <div style="height:60vh;overflow-y:auto">
          <el-form :model="form" size="small" label-width="80px">
            <el-form-item label="类型" :label-width="formLabelWidth">
              <!-- <el-input v-model="" autocomplete="off" /> -->
              <el-select v-model="form.type" placeholder="请选择类型" style="width:100%">
                <el-option label="产品与服务" :value="1" />
                <el-option label="关于我们" :value="2" />
                <el-option label="我们的案例" :value="3" />
              </el-select>
            </el-form-item>
            <el-form-item label="跳转地址" :label-width="formLabelWidth">
              <el-input v-model="form.value" autocomplete="off" placeholder="请输入地址" />
            </el-form-item>
            <el-form-item label="标题" :label-width="formLabelWidth">
              <el-input v-model="form.name" autocomplete="off" placeholder="请输入标题" />
            </el-form-item>

          </el-form>
        </div>
        <div slot="footer" class="dialog-footer" style="display: flex;margin-top: 10px;">
          <el-button size="small" style="margin-left: auto; margin-right: 30px;" @click="handleBeforeClose">取 消</el-button>
          <el-button type="primary" :loading="subLoading" size="small" style="margin-right: 50px;" @click="handleSubmit()">确 定</el-button>
        </div>
      </div>
    </el-drawer>
    <div class="table">
      <div class="header">
        <el-input
          v-model="query.searchText"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          style="width:174px"
          size="mini"
        />
        <el-select
          v-model="query.type"
          placeholder="请选择类型"
          size="mini"
          style="margin-left:10px"
          @change="handleTypeChange"
        >
          <el-option label="产品与服务" :value="1" />
          <el-option label="关于我们" :value="2" />
          <el-option label="我们的案例" :value="3" />
        </el-select>

        <el-button type="primary" size="mini" @click="gettableData">查询</el-button>
        <div style="float:right;">
          <el-button type="success" size="mini" @click="handleEdit()">新增</el-button>
          <el-button type="danger" size="mini" @click="BatchhandleDelet()">批量删除</el-button>
        </div>
      </div>
      <div>
        <el-table :data="tableData" stripe fit @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="name" label="标题" />
          <el-table-column prop="value" label="跳转地址" />
          <el-table-column prop="type" label="类型">
            <template slot-scope="scope">
              <div v-if="scope.row.type === 1">
                产品与服务
              </div>
              <div v-else-if="scope.row.type === 2">
                关于我们
              </div>
              <div v-else>
                我们的案例
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click="handleEdit(scope.row)"
              >编辑</el-button>
              <el-button type="text" size="small" @click="handleDelet(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="block">
          <el-pagination
            :current-page="query.pageNum"
            :page-sizes="[5, 10, 20, 30, 40]"
            :page-size="query.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            size="small"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDatamanagement,
  getSubmit,
  handlDel
} from '../../api/admin/friendchain'
// import { getPare } from '../../api/admin/friendchain'
export default {
  data() {
    return {
      uploadAttachments: [],
      tableData: [],
      optionsObj: {},
      query: {
        pageNum: 1,
        pageSize: 10,
        searchText: '',
        type: ''
      },
      total: 0,
      formType: 0, // 0新增 1编辑
      form: {
        type: '',
        name: ''
      },
      dialogFormVisible: false,
      subLoading: false,
      ids: [],
      //   token: '',
      options: [],
      type: ''
    }
  },
  created() {
    // this.token = localStorage.getItem('token')
    // this.handeltype()
    this.gettableData()
  },
  methods: {
    // 获取数据
    gettableData() {
      getDatamanagement(this.query)
        .then(res => {
          console.log('res', res)
          this.tableData = res.data.page.records
          this.total = res.data.page.total
        })
    },
    handleTypeChange() {
      this.query.pageNum = 1
      this.gettableData()
    },
    // 分页
    handleSizeChange(e) {
      console.log('handleSizeChange', e)
      this.query.pageSize = e
      this.gettableData()
    },
    handleCurrentChange(e) {
      console.log('handleCurrentChange', e)
      this.query.pageNum = e
      this.gettableData()
    },
    // 编辑
    handleEdit(row) {
      // 0新增 1编辑
      this.formType = row ? 1 : 0
      console.log('handleEdit', row)
      if (row) {
        this.form = JSON.parse(JSON.stringify(row))
        if (!this.form.images) {
          this.form.images = []
        }
      }
      this.dialogFormVisible = true
    },
    // 点击取消，弹窗关闭
    handleClose() {
      this.dialogFormVisible = false
      this.form = {}
    },
    // handleClose(done) {
    //   this.$confirm('确认关闭？')
    //     .then(_ => {
    //       done()
    //     })
    //     .catch(_ => {})
    // },
    // 提交
    handleSubmit() {
      this.subLoading = true
      getSubmit(this.form)
        .then(res => {
          this.subLoading = false
          if (this.formType === 0) {
            this.query.pageNum = 1
          }
          this.handleClose()
          this.gettableData()
        })
        .catch(e => {
          this.subLoading = false
        })
    },
    // 选取某一行数据
    handleSelectionChange(val) {
      console.log('handleSelectionChange', val)
      this.ids = val.map(o => o.id)
      console.log('ids', this.ids)
    },

    // 删除
    handleDelet(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel([row.id]).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.gettableData()
          })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    // 批量删除
    BatchhandleDelet() {
      if (this.ids.length <= 0) {
        this.$notify({
          title: '警告',
          message: '请选择要删除的行',
          duration: 1500,
          type: 'warning'
        })
        return
      }
      this.newsLoading = true
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handlDel(this.ids).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.gettableData()
          })
        })
        .catch(e => {
          this.newsLoading = false
          console.error(e)
          console.log(e, '取消 删除')
        })
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后未保存的数据将会丢失，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.handleClose()
        })
    }
    // 获取类型
    // handeltype() {
    //   getPare(this.type)
    //     .then(res => {
    //       console.log('getPare', res)
    //       this.options = res.data.items
    //       // const optionsObj = {}
    //       this.options.map(o => {
    //         this.optionsObj[o.type] = o.title
    //       })
    //       console.log('optionsObj', this.optionsObj)
    //       this.value = this.optionsObj
    //     })
    // }

  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item__content {
    width: 300px;
  }
}
</style>

<style lang="scss">
.table {
  border: 1px solid #ddd;
  .header {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    .el-button {
      margin-left: 10px;
    }
  }
}
/* .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  margin: 10px;
} */
.el-pagination {
  margin: 10px;
  text-align: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-dialog {
  // overflow: scroll;
  //隐藏侧边默认滚动条 （达不到效果可以自己试着微调） 如解决不了可尝试用 `margin-right -60px`
  // height: 70%;
}
</style>
